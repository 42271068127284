import { createSlice } from '@reduxjs/toolkit'
import hit from 'src/services/manager'

const initialState = {
    list: []
}

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setList: (state, action) => {
            state.list = action.payload
        }
    }
})

export const { setList } = usersSlice.actions

export const getAllUsers = () => {
    return async (dispatch) => {
        let res = await hit("/admins/users", "get")
        if (!res?.err) {
            dispatch(setList(res.data))
        }
    }
}

export default usersSlice.reducer