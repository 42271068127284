import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import hit from 'src/services/manager'

const initialState = {
    list: []
}

const ordersSlice = createSlice({
    name: "scores",
    initialState,
    reducers: {
        setScoreList: (state, action) => {
            state.list = action.payload
        }
    }
})

export const { setScoreList } = ordersSlice.actions

export const getScoresListThunk = (team_id = null, dateRanges = null) => {
    return async (dispatch) => {
        let res = null
        if (team_id) {
            if (dateRanges) {
                let query = `startDate=${moment(dateRanges[0]).format("MM/DD/yyyy")}&endDate=${moment(dateRanges[1]).format("MM/DD/yyyy")}`
                res = await hit(`/admins/scores/${team_id}?${query}`, "get")
            } else {
                res = await hit(`/admins/scores/${team_id}`, "get")
            }
        } else {
            res = await hit(`/admins/scores/`, "get")
        }
        if (res && !res.err) {
            dispatch(setScoreList(res?.data))
        }
    }
}

export default ordersSlice.reducer