import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header';
import Nav from './nav';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataThunk } from 'src/redux/auth';
import { getAllUsers } from 'src/redux/users';
import { getSupportsThunk } from 'src/redux/support';
import { useEffect } from 'react';
import { getTeamsListThunk } from 'src/redux/teams';
import { getPostListThunk } from 'src/redux/posts';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth?.user)

  useEffect(() => {
    dispatch(getUserDataThunk())
  }, [])

  useEffect(() => {
    if (user?.role) {
      if (user?.role !== "user") {
        dispatch(getAllUsers())
        dispatch(getSupportsThunk())
        dispatch(getTeamsListThunk())
        dispatch(getPostListThunk())
      }
    }
  }, [user])

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}